.c-text{
    font-family: 'WorkSans';
    font-weight: $normal;
    font-size: $font-size;
    line-height: 1.2rem;
    color: $blue;
    text-align: left!important;

    p {
        text-align: left!important;
    }

    &.-font-larger{
        font-size: $font-size-h6;
    }

    ul {
        padding-left: 0;
        li {
            list-style-type: none;
            position: relative;
            padding-left: calc(#{rem(10px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                content: " ";
                position: absolute;
                left: 0;
                width: rem(10px);
                height: rem(10px);
                // background-color: currentColor;
                background-color: $primary;
                border-radius: 50%;
                top: calc(#{$font-size} - #{rem(10px)});
            }
        }
    }
    ol {
        counter-reset: ol_counter; /* on initialise et nomme un compteur */
        padding: 0;
        li {
            padding: 0;
            list-style-type: none;
            counter-increment: ol_counter; /* on incrémente le compteur à chaque nouveau li */
            position: relative;
            padding-left: calc(#{rem(12px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &::before {
                content: counter(ol_counter)"."; /* on affiche le compteur */
                width: rem(12px);
                height: rem(12px);
                position: absolute;
                font-weight: $bold;
                left: 0;
                color: $primary;
            }
        }
    }

    .alignleft,
    img.alignleft {
        display: inline;
        float: left;
        margin-right: 1.5em;
    }
    .alignright,
    img.alignright {
        display: inline;
        float: right;
        margin-left: 1.5em;
    }
    .aligncenter,
    img.aligncenter {
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    // p {
    //     clear: both;
    // }
}
