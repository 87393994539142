// ==========================================================================
// Utilities / Spacing
// ==========================================================================

////
/// Utility classes to put specific spacing values onto elements. The below loop
/// will generate us a suite of classes like:
///
/// @example
///   .u-margin-top {}
///   .u-padding-left-large {}
///   .u-margin-right-small {}
///   .u-padding {}
///   .u-padding-right-none {}
///   .u-padding-horizontal {}
///   .u-padding-vertical-small {}
///
/// @link https://github.com/inuitcss/inuitcss/blob/512977a/utilities/_utilities.spacing.scss
////

/* stylelint-disable string-quotes */

$spacing-directions: (
    null:          null,
    '-top':        '-top',
    '-right':      '-right',
    '-bottom':     '-bottom',
    '-left':       '-left',
    '-horizontal': '-left' '-right',
    '-vertical':   '-top'  '-bottom',
) !default;

$spacing-properties: (
    'padding': 'padding',
    'margin':  'margin',
) !default;

$spacing-sizes: (
    null:     $unit,
    '-double': $unit * 2,
    '-small': $unit-small,
    '-medium' : 2 / 3 * $unit-large,
    '-large': $unit-large,
    '-extra-large': $unit-large * 2,
    '-tiny': $unit-tiny,
    '-tinier': $unit-tiny / 2,
    '-none':  0px
) !default;

@each $property-namespace, $property in $spacing-properties {
    @each $direction-namespace, $direction-rules in $spacing-directions {
        @each $size-namespace, $size in $spacing-sizes {
            .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {
                @each $direction in $direction-rules {
                    #{$property}#{$direction}: rem($size) !important;
                }
            }
        }
    }
}

/* Responsive spacing */
$breakpoints-to: (
  'to-small': $to-small,
  'to-medium': $to-medium,
  'to-large': $to-large,
  'to-midsmall': $to-midsmall,
) !default;
@if $breakpoints-to {
  @each $property-namespace, $property in $spacing-properties {
    @each $direction-namespace, $direction-rules in $spacing-directions {
      @each $size-namespace, $size in $spacing-sizes {
        @each $breakpoint, $breakpoint-size in $breakpoints-to {
          .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}#{$breakpoint-delimiter}#{$breakpoint} {
            @media (max-width: #{$breakpoint-size}) {
              @each $direction in $direction-rules {
                #{$property}#{$direction}: rem($size) !important;
              }
            }
          }
        }
      }
    }
  }
}

$breakpoints-from: (
  'from-tiny': $from-tiny,
  'from-small': $from-small,
  'from-medium': $from-medium,
  'from-large': $from-large,
  'from-midsmall': $from-midsmall,
) !default;
@if $breakpoints-from {
  @each $property-namespace, $property in $spacing-properties {
    @each $direction-namespace, $direction-rules in $spacing-directions {
      @each $size-namespace, $size in $spacing-sizes {
        @each $breakpoint, $breakpoint-size in $breakpoints-from {
          .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}#{$breakpoint-delimiter}#{$breakpoint} {
            @media (min-width: #{$breakpoint-size}) {
              @each $direction in $direction-rules {
                #{$property}#{$direction}: rem($size) !important;
              }
            }
          }
        }
      }
    }
  }
}

/* stylelint-enable string-quotes */
