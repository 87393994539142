.b-organigramme{
    overflow: hidden;

    // .o-layout_item{
    //     @media (min-width: $from-medium){
    //         min-width: 33.33%;
    //     }

    //     @media (max-width: $from-small){
    //         min-width: 50%;
    //     }
    // }

    .c-slider_nav{
        z-index: 2;
        min-height: 100%;
        display: flex;
        align-items: center;
        background-color: $light-blue;

        // &.-next{
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;
        // }
    }

    .wrapper{
        overflow: hidden;
    }

    .c-slider_container{
        overflow: hidden;
        position: relative;
    }

    .c-slider_container {
        display: grid;
        grid-template-columns: 2.5rem 1fr 2.5rem;
        gap: rem($unit-small);
    }
    .c-slider_wrapper {
        min-width: 100%;
        overflow: hidden;
    }
}
