.b-accueil-intro {
    // background-color: $black;
    height: calc(100vh - #{rem($header_height)});
    display: flex;
    align-items: center;
    position: relative;
    .b-accueil-intro_scrollTo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem($unit);
    }
    .b-accueil-intro_deco1 {
        position: absolute;
        pointer-events: none;
        top: rem($unit-small);
        right: rem(-$unit);
        transform: rotate(-28deg);
        svg {
            width: rem(220px);
        }
    }

    .b-accueil-intro_deco2 {
        position: absolute;
        pointer-events: none;
        bottom: rem(-$unit-small);
        left: 0;
        transform: rotate(54deg);
        svg {
            width: rem(220px);
        }
    }

    .b-accueil-intro_deco3 {
        position: absolute;
        pointer-events: none;
        top: rem($unit);
        left: rem($unit);
        transform: rotate(-28deg);
        svg {
            width: rem(93px);
        }
    }

    .b-accueil-intro_deco4 {
        position: absolute;
        pointer-events: none;
        bottom: rem($unit-large);
        right: rem($unit-large);
        transform: rotate(54deg);
        svg {
            width: rem(93px);
        }
    }
}