.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transition: $speed $easing;
  .c-modal_bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($blue, 0.8);
  }
  // .c-modal_close {
  //   position: absolute;
  //   top: rem($unit);
  //   left: 50%;
  //   cursor: pointer;
  //   z-index: 2;
  //   height: 3rem;
  //   width: 3rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   .c-button{
  //     width: 100%;
  //     padding: inherit;
  //     height: 100%;
  //     border: 2px solid $grey;
  //   }
  // }
  // img {
  //   position: relative;
  // }
  .c-modal_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem($unit);
      position: relative;
      height: 100%;
      pointer-events: none;
      .c-modal_wrap {
          max-width: rem($container-small-width);
          width: 100%;
          background-color: $light-blue;
          border-radius: 8px;
          position: relative;
          pointer-events: auto;
          // box-shadow: 0 10px 30px rgba($shadow, 0.16);
          overflow: hidden;
          .c-modal_head {
              background-color: $primary;
              color: $white;
              padding: rem($unit-tiny) rem($unit-small);
              display: flex;
              align-items: center;
              justify-content: space-between;
          }
          .c-modal_content {
              position: relative;
              z-index: 2;
              // padding: rem($unit-small);
              max-height: 80vh;
              overflow: auto;
          }
          .c-modal_image {
              height: rem(200px);
              position: relative;
          }
          .hr {
              flex: 1;
              height: 1px;
              background-color: $black;
          }
      }
  }
  &.is-open {
      visibility: visible;
      opacity: 1;
  }

  .c-modal_search {
    color: $white;
    position: relative;
    .c-form_label {
        font-size: $font-size-h1;
        opacity: 1;
        left: 0;
    }
    .c-form_input {
        border-bottom: 4px solid $grey;
        font-size: $font-size-h3;
        line-height: 1;
        padding: 0.7rem 0;
        padding-right: rem(50px);
        height: rem(100px);
        color: $grey;
    }
    .c-modal_search_button {
        position: absolute;
        right: 0;
        bottom: calc(4px + 1rem);
        svg {
            width: rem(40px);
        }
    }
    .c-form_item.is-active label {
        transform: translateY(-100%);
    }
}
}