// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$primary: #3C91D7;
$second: #D0307C;
$blue: #10456C;
$light-blue: #E5EDF9;
$gradient1: #C956AA;
$gradient2: #536bd1;
$gradient3: #538FD1;
$red-gradient1: #C94242;
$red-gradient2: #EE6161;
$black: #606060;
$red: #F27070;
$green: green;
$grey: #606060;
$grey-cod: #c0d1DB;
$grey-light: #C3D1DA;
$grey-lighter: rgba($grey, 0.3);
$grey-footer: #2F343B;
$white-footer: #ECF0F5;
$white-footer2 : darken($white-footer, 0.5);
$black-overlay-light: rgba(0, 0, 0, 0.7);
$blue-overlay-light: rgba($second, 0.9);

$hr: #C0D1D8;


// Specific
// =============================================================================
// Link
$link-color:       $grey;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: $white;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
