.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-white {
    color: $white;
}
.u-c-white2 {
    color: $white-footer2;
}
.u-c-grey {
  color: $grey;
}
.u-c-red {
  color: $red;
}
.u-c-blue {
  color: $blue;
}
.u-c-lightBlue {
  color: $light-blue;
}
.u-c-cod {
  color: $grey-cod;
}
.u-c-gradient{
  background: linear-gradient(90deg, rgba(201,86,170,1) 0%, rgba(201,86,170,1) 10%, rgba(83,143,209,1) 90%, rgba(83,143,209,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-red{
  background-color: $red;
}
.u-bg-black {
    background-color: $black;
}
.u-bg-blue{
  background-color: $blue;
}
.u-bg-blue-light {
  background-color: rgba($blue, 0.15);
}
.u-bg-white {
    background-color: $white;
}
.u-bg-lightBlue {
  background-color: $light-blue;
}

.u-c-gradient-title {
  background: linear-gradient(45deg, $gradient1, $gradient2, $gradient3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}