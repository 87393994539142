.b-cta{
    &.-large{
        min-height: 730px;
    }

    .b-cta_header{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $blue;
    }

    .b-cta_title{
        position: relative;

        span{
            &:first-of-type{
                z-index: 1;
                position: relative;
            }

            &:last-of-type{
                font-size: rem(110px);
                position: absolute;
                left: -50%;
                right: -50%;
                bottom: 1rem;
                color: rgba(255, 255, 255, $alpha: 0.12);
                z-index: 0;
                white-space: nowrap;
            }
        }
    }

    .b-cta_btn{
        position: absolute;
        bottom: -25px;
        left: 35%;
        right: 35%;
        display: flex;
        justify-content: center;
    }

    .b-cta_lien_externe{
        span{
            margin-left: 0.5rem;
            
            svg{
                path{
                    fill: $grey;
                }
            }
        }
    }

    @media (max-width: 570px){
        .b-cta_deco{
            .o-container{
                padding-left: 0;
                padding-right: 0;
                div{
                    &:last-of-type{
                        padding: 0 !important;
                    }
                }
            }
        }
    }
}
