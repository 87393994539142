.swiper-pagination-bullet {
  width: rem(70px) !important;
  height: rem(6px) !important;
  background-color: $primary !important;
  opacity: 0.36 !important;
  border-radius: 0 0 0 rem(7px) !important;
  &.swiper-pagination-bullet-active {
    opacity: 1 !important;
  }
}
.swiper-pagination-bullets.is-white {
  .swiper-pagination-bullet {
    background-color: $white !important;
  }
}
.swiper-pagination {
  position: relative !important;
}

.-small {
  .swiper-pagination-bullet {
    width: rem(35px) !important;
  }
}

.swiper-navigation_button {
  &.is-disabled {
    opacity: 0.5;
    cursor: default;
  }
  &.-prev {
    svg {
      transform: rotate(180deg);
    }
  }
}

.swiper-slide {
  height: auto !important;
}
