.keeo-ged {
  display: grid;
  grid-template-columns: 350px 1fr;
  .nav-wrapper {
    overflow: auto;
    background-color: map-get($dashboard-colors, 'g-light');
    padding: 4rem 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    .folder-wrapper {
      color: map-get($dashboard-colors, 'g');
      border-top: 1px solid map-get($dashboard-colors, 'g');
      padding: 1.5rem 0 0;
      .folder-divblock {
        &:nth-child(n) {
          padding-left: 1.5rem;
        }
        &.--first {
          padding-left: 0
        }
        &:not(.--first) {
          display: none;
        }
        &.open {
          display: block;
        }
        .folder-item {
          border-radius: 5rem 0 0 5rem;
          padding: 0.5rem 1.5rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all 500ms ease;
          .title {
            display: flex;
            align-items: center;
            width: 100%;
          }
          .icon {
            margin-right: 1rem;
            font-size: 1.5rem;
          }
          .arrow {
            font-size: 1.5rem;
            margin-left: auto;
            transition: all 500ms ease;
          }
          &:hover {
            background-color: map-get($dashboard-colors, 'w');
          }
          &.selected {
            background-color: #ccc;
          }
          &.active {
            .arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .logout-wrapper {
      border-top: 1px solid map-get($dashboard-colors, 'g');
      padding: 1.5rem 1.5rem 0;
      margin-top: auto;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: map-get($dashboard-colors, 'g');
      }
      .icon {
        transform: rotate(180deg);
        font-size: 1.5rem;
        display: block;
      }
      .title {
        margin-left: 1rem;
      }
    }
  }
  .content-wrapper {
    padding: 4rem;
    .filters-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      margin-top: 4rem;
      .filter-divblock {
        display: flex;
        align-items: center;
      }
      .switch-view {
        display: flex;
        color: map-get($dashboard-colors, 'g');
        .item {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          margin-left: 2rem;
          cursor: pointer;
          .icon {
            font-size: 1.5rem;
            margin-right: 1rem;
            display: block;
          }
          &.active, &:hover {
            background-color: map-get($dashboard-colors, 'g-light');
          }
        }
      }
    }
    .results-wrapper {
      margin-top: 2rem;
      background-color: map-get($dashboard-colors, 'g-light');
      padding: 2rem;

      .folder-title {
        margin-bottom: 1rem;
        .folder-item {
          display: inline;
          &:not(.active) {
            cursor: pointer;
          }
        }
        i, svg {
          color: #666;
        }
      }
    }
    .ged-table-divblock {
      display: none;
      &.active {
        display: block;
      }


      table {
        border-spacing: 0rem 1rem;
        color: #666;
        border-color: #ccc;
        thead {
          th {
            color: #555;
            border-color: #ccc;
            border-width: 2px;
          }
        }
        tbody {
          tr {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
            // &:not(:last-of-type) {
            //   td {
            //     border-bottom: 1px solid #999;
            //   }
            // }
            td {
              &:last-of-type {
                background-color: darken($color: #fff, $amount: 10%);
                min-width: 30px;
                text-align: center;
                color: #666 !important;
              }
            }
            &.selected {
              background-color: initial;
              color: #4285F4;
            }
          }
        }
      }

      .tab-actions-divblock {
        user-select: none;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 20px;

          svg {
            position: absolute;
            transition: all 300ms ease;
          }
        }

        .active {
          .icon {
            svg {
              &:first-of-type {
                transform: rotate(45deg);
              }

              &:last-of-type {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .actions {
          display: none;
          user-select: none;
          overflow: hidden;
          color: #666 !important;
          position: absolute;
          background: white;
          border-radius: .5rem;
          right: 0;
          flex-direction: column;
          align-items: flex-start;
          border: 1px solid #bbb;
          
          &.open {
            display: flex;
          }

          .item {
            color: inherit;
            text-decoration: none;
            position: relative;
            padding: 0 1rem;
            width: 100%;
            cursor: pointer;
            padding-top: .5rem;
            padding-bottom: .5rem;
            transition: all 500ms ease;
            &:hover {
              background-color: darken(#fff, 5%);
            }

            &:not(:first-child)
            {
              &:after {
                content: '';
                position: absolute;
                left: -1rem;
                height: 1px;
                width: 10rem;
                background-color: #ccc;
                top: 0;
              }
            }
          }
        }
      }
    }
    .ged-grid-divblock {
      display: none;
      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 2rem;
        
        .card-img-top {
          width: 100%;
          border-top-left-radius: calc(0.25rem - 1px);
          border-top-right-radius: calc(0.25rem - 1px);
          height: 200px;
          overflow: hidden;
          img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
          }
        }
  
        .card-body {
          position: relative;
          
          .tab-actions-divblock {
            user-select: none;
            display: flex;
            position: absolute;
            top: 1.25rem;
            right: 1rem;
    
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 20px;
    
              svg {
                position: absolute;
                transition: all 300ms ease;
              }
            }
    
            .active {
              .icon {
                svg {
                  &:first-of-type {
                    transform: rotate(45deg);
                  }
    
                  &:last-of-type {
                    transform: rotate(-45deg);
                  }
                }
              }
            }
    
            .actions {
              display: none;
              user-select: none;
              overflow: hidden;
              color: #666 !important;
              position: absolute;
              background: white;
              border-radius: .5rem;
              right: 0;
              flex-direction: column;
              align-items: flex-start;
              border: 1px solid #bbb;
              top: 1.25rem;
    
              &.open {
                display: flex;
              }
    
              .item {
                color: inherit;
                text-decoration: none;
                position: relative;
                padding: 0 1rem;
                width: 100%;
                cursor: pointer;
                padding-top: .5rem;
                padding-bottom: .5rem;
                transition: all 500ms ease;
    
                &:hover {
                  background-color: darken(#fff, 5%);
                }
    
                &:not(:first-child) {
                  &:after {
                    content: '';
                    position: absolute;
                    left: -1rem;
                    height: 1px;
                    width: 10rem;
                    background-color: #ccc;
                    top: 0;
                  }
                }
              }
            }
          }
        }
        
  
        .grid-item {
          padding: 1.5rem;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
          background-color: map-get($dashboard-colors, 'w');
        }
      }
      &.active {
        display: block;
      }
    }
  }
}
