// ==========================================================================
// Utilities / Helpers
// ==========================================================================

// Layout
// ==========================================================================
.u-clearfix {
  @include u-clearfix;
}

// Decorative
// =============================================================================
.u-truncate {
  @include u-truncate;
}

.u-bold {
  font-weight: $bold;
}
.u-medium {
  font-weight: $medium;
}

.u-semiBold {
  font-weight: $semibold;
}

.u-normal {
  font-weight: $normal;
}

.u-light {
  font-weight: $light;
}

.u-100 {
  height: 100%;
}

.u-h-100 {
  height: 100%;
}
.u-w-100 {
  width: 100%;
}
.u-mw-100 {
  max-width: 100%;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-p-small {
  font-size: rem($font-size-small);
}

.u-margin-top-auto {
  margin-top: auto;
}

.u-margin-left-auto {
  margin-left: auto;
}

.u-overflow-hidden {
  overflow: hidden;
}

//  Visibility / Display
// ==========================================================================
[hidden][aria-hidden="false"] {
  position: absolute;
  display: inherit;
  clip: rect(0, 0, 0, 0);
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

// .u-block {
//     display: block;
// }

// /**
//  * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
//  * inline-block will display at its default size, and not limit its width to
//  * 100% of an ancestral container.
//  */
// .u-inline-block {
//     display: inline-block !important;
//     max-width: 100%; /* 1 */
// }

// .u-inline {
//     display: inline !important;
// }

// .u-table {
//     display: table !important;
// }

// .u-tableCell {
//     display: table-cell !important;
// }

// .u-tableRow {
//     display: table-row !important;
// }

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-screen-reader-text {
  @include u-accessibly-hidden;
}

@media not print {
  .u-screen-reader-text\@screen {
    @include u-accessibly-hidden;
  }
}

/*
 * Extends the `.screen-reader-text` class to allow the element
 * to be focusable when navigated to via the keyboard.
 *
 * @link https://www.drupal.org/node/897638
 * @todo Define styles when focused.
 */
.u-screen-reader-text.-focusable {
  @include u-accessibly-focusable;
}

.u-flex {
  display: flex;
  &.-top {
    align-items: flex-start;
  }
  &.-middle {
    align-items: center;
  }
  &.-bottom {
    align-items: flex-end;
  }
  &.-column {
    flex-direction: column;
  }
  &.-start {
    justify-content: flex-start;
  }
  &.-end {
    justify-content: flex-end;
  }
  &.-center {
    justify-content: center;
  }
  &.-space {
    justify-content: space-between;
  }
  &.-wrap {
    flex-wrap: wrap;
  }
  &.-gap-tinier {
    gap: rem($unit-tinier);
  }
  &.-gap-tiny {
    gap: rem($unit-tiny);
  }
  &.-gap-small {
    gap: rem($unit-small);
  }
  &.-gap {
    gap: rem($unit);
  }
}

.u-flex1 {
  flex: 1;
}

.u-relative {
  position: relative;
}
