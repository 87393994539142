.c-card {
  position: relative;
  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
    background-color: white;
    border-radius: 0 0 0 1.5rem;
    overflow: hidden;
    max-height: rem(267px);

    .c-card_image {
      figure{
        height: rem(200px);
        overflow: hidden;
      }
      img{
        transition: 0.3s ease-out;
        object-position: top;
      }
    }

    &:hover {
      .c-card_image {
        overflow: hidden;
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &.-actualite{
    overflow: hidden;
    border-bottom-left-radius: 2rem;
    .c-card_image{
      // height: 170px;
    }
    figure{
      position: relative;
      overflow: hidden;
      border-bottom-left-radius: 2rem;

      &.-default{
        padding: 2rem;
      }

      img{
        transition: 0.3s ease-out;
      }
    }

    figcaption{
      position: absolute;
      padding: 0.8rem 2rem;
      bottom: 0;
      left: 0;
      font-weight: $semibold;
      border-bottom-left-radius: 1rem;
    }

    .c-card_body{
      div{
        transition: 0.3s ease-out;
      }

      .c-card_body_date{
        color: rgba($color: $blue, $alpha: 0.5);
      }

      .c-card_body_text{
        display: flex;
        align-items: center;

        span{
          padding: 0 1rem;
        }
      }
    }

    &:hover{
      figure{
        img{
          transform: scale(1.05);
        }
      }

      .c-card_body{
        div{
          color: $primary;
        }
      }
    }

    .c-card_content{
      display: grid;
      align-items: flex-start;
      padding: 1rem;
      background-color: white;
      // min-height: 230px;
    }

    &::after{
      content: "";
      height: 15px;
      width: 100%;
      position: absolute;
      bottom: -15px;
      left: 0;
      transition: 0.3s ease-out;
      background: linear-gradient(90deg, rgba(201,86,170,1) 0%, rgba(201,86,170,1) 10%, rgba(83,143,209,1) 90%, rgba(83,143,209,1) 100%);
    }

    &:hover{
      &::after{
        bottom: 0;
      }
    }
  }

  &.-projets{
    border: 2px solid $grey;
    padding: 1.5rem;
    border-radius: 1.5rem;
    transition: 0.3s ease-out;
    min-height: 220px;
    position: relative;
    .c-card_body{
      .u-c-grey{
        transition: 0.3s ease-out;
      }
    }
    
    .c-card_foot{
      position: absolute;
      bottom: 1.5rem;
    }

    &:hover{
      .c-card_body{
          .u-c-grey{
            color: $primary;
          }
      }
      border: 2px solid $primary;
    }

    @media (max-width: $from-small){
      min-height: 170px;
    }
  }

  &.-equipe{
    padding: 1rem 1.5rem;
    border-bottom-left-radius: 1.5rem;
    background-color: $blue;
    color: white;
  }

  &.-services{
    background-color: white;
    border-bottom-left-radius: 1.5rem;
    overflow: hidden;
    
    .c-card_image{
      width: rem(120px);
      min-width: rem(120px);
      // height: 100%;
      background-color: $blue;
      // padding: 1rem;
      min-height: rem(120px);
    }

    .c-card_content{
      width: 100%;
      justify-content: space-between;

      svg{
        margin-left: 0.5rem;
        min-width: 1rem;
      }
    }

    &::after{
      content: "";
      height: 15px;
      width: 100%;
      position: absolute;
      bottom: -15px;
      left: 0;
      transition: 0.3s ease-out;
      background: linear-gradient(90deg, rgba(201,86,170,1) 0%, rgba(201,86,170,1) 10%, rgba(83,143,209,1) 90%, rgba(83,143,209,1) 100%);
    }

    &:hover{
      &::after{
        bottom: 0;
      }
    }
  }

  &.-specialites{
    background-color: white;
    border-bottom-left-radius: 1.5rem;
    overflow: hidden;

    .c-card_content{
      width: 100%;
      justify-content: space-between;

      svg{
        margin-left: 0.5rem;
        min-width: 1rem;
      }
    }

    &::after{
      content: "";
      height: 15px;
      width: 100%;
      position: absolute;
      bottom: -15px;
      left: 0;
      transition: 0.3s ease-out;
      background: linear-gradient(90deg, rgba(201,86,170,1) 0%, rgba(201,86,170,1) 10%, rgba(83,143,209,1) 90%, rgba(83,143,209,1) 100%);
    }

    &:hover{
      &::after{
        bottom: 0;
      }
    }
  }

  &.-lien {
    position: relative;
    border-radius: 0 0 0 rem(10px);
    // overflow: hidden;
    border: 3px solid rgba($blue, 0.15);

    &::after {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      right: 0px;
      bottom: -3px;
      border-left: 3px solid $primary;
      border-bottom: 3px solid $primary;
      border-bottom-left-radius: rem(10px);
      visibility: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      left: -3px;
      top: -3px;
      border-top: 3px solid $primary;
      border-right: 3px solid $primary;
      visibility: hidden;
    }
    .c-card_wrap {
      display: grid;
      grid-template-columns: 1fr rem(50px);
    }
    .c-card_content {
      display: grid;
      grid-template-columns: rem(50px) 1fr;
      gap: rem($unit-tiny);
      align-items: center;
    }
    .c-card_icon {
      width: rem(52px);
      height: rem(52px);
      border-radius: 50%;
      background-color: $light-blue;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .c-card_deco {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid $grey-light;
    }

    &:hover {
      &::after {
        visibility: visible;
        height: calc(100% + 6px);
        width: calc(100% + 3px);
        transition: width 0.15s linear 0.3s, visibility 0s linear 0.3s, height 0.15s linear 0.45s;
      }
      &::before {
        visibility: visible;
        height: calc(100% + 6px);
        width: calc(100% + 6px);
        transition: width 0.15s linear, height 0.15s linear 0.15s;
      }
    }
  }

  &.-offre2 {
    background-color: $white;
    transition: $speed $easing;
    color: $primary;
    border-bottom-left-radius: rem($unit-tiny);
    overflow: hidden;
    .c-card_wrap {
      padding: rem($unit-small);
    }

    .c-card_icon {
      margin-left: auto;
      svg {
        width: rem(20px);
      }
    }

    .c-heading, .c-card_icon {
      transition: $speed $easing;
    }

    img {
      border-bottom-left-radius: rem($unit-tiny);
    }

    &:hover {
      background-color: $blue;
      .c-heading, .c-card_icon {
        color: $white;
      }
    }
  }
}
