.b-temoignage{
    .b-temoignage_container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

        @media (max-width: 700px){
            grid-template-columns:  1fr;
        }
    }
    .b-temoignage_component{
        border-bottom-left-radius: 1rem;
        overflow: hidden;

        span{
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            transform: translateY(0.8rem);
        }

        .b-temoignage_bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            transform: translateY(1.5rem);

            div{
                &:last-of-type{
                    display: flex;
                    align-items: center;

                    &::before{
                        content: '';
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        background-color: $primary;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
}
