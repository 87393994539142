.b-fichiers {
    background: linear-gradient(to right, #c956aa, #538fd1 60%, #538fd1 100%);

    .b-fichiers_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: rem($unit-tiny);

        @media(max-width: $from-tiny){
            grid-template-columns: 1fr;
        }
    }

    .b-fichiers_wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(max-width: 700px){
            flex-direction: column;
        }
    }

    .b-fichiers_component{
        display: flex;
        justify-content: space-between;
        &.-center {
            grid-column: span 2;
        }
    }
}
