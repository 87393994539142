.c-header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  // height: rem($header-height);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 2px solid rgba($color: $primary, $alpha: 0.15);

  .c-header_top,
  .c-header_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  .c-header_top {
    border-bottom: 1px solid rgba($color: $primary, $alpha: 0.15);
    z-index: 2;
    position: relative;

    a {
      &.c-header_button {
        padding: 0.3rem 0.8rem;
        border: 1px solid $primary;
        border-radius: 1rem;
        transition: 0.3s ease-out;

        &:hover {
          background-color: $primary;
          color: white;
        }
      }
    }

    @media (max-width: 470px) {
      a {
        &.c-header_button {
          display: none;
        }
      }
    }
    @media (max-width: $to-small) {
      flex-direction: column;
    }
  }

  .c-header_bottom {
    z-index: 2;
    position: relative;
    background-color: white;

    .logo {
      max-height: calc(90px - 1rem);

      &:last-of-type {
        display: none;
      }

      @media (max-width: $from-tiny) {
        &:first-of-type {
          display: none;
        }

        &:last-of-type {
          display: flex;
          position: absolute;
          top: 0.5rem;
        }
      }
    }

    .c-header_links {
      text-transform: uppercase;
      justify-content: space-between;
      display: flex;
      align-items: center;

      a {
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:last-of-type {
          margin-right: 4rem;
        }

        &::after {
          content: "";
          margin-top: 0.2rem;
          width: 100%;
          height: 1px;
          background-color: $primary;
          transform-origin: left;
          transform: scaleX(0%);
          transition: 0.3s ease-out;
        }

        &:hover {
          &::after {
            transform: scaleX(100%);
          }
        }
      }

      div {
        cursor: pointer;
      }
    }

    .c-header_buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        margin-right: 1rem;
        padding: 0 1.5rem;
        border-bottom-left-radius: 1rem;
        color: white;
        height: rem(45px);
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;

        img {
          position: relative;
          z-index: 2;
          display: none;
        }

        span {
          position: relative;
          z-index: 2;
        }

        &::after {
          content: "";
          width: 200%;
          height: 100%;
          background: linear-gradient(
            to right,
            $red-gradient1,
            $red-gradient2,
            $red-gradient1
          );
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.3s ease-out;
          z-index: 0;
        }

        &:hover {
          &::after {
            transform: translateX(-50%);
          }
        }

        @media (max-width: 1200px) {
          img {
            display: flex;
            min-width: 25px;
          }
          span {
            display: none;
          }
        }
      }

      .c-header_menu {
        height: rem(45px);
        transition: 0.3s ease-out;

        .c-header_nav_icon_burger {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .c-header_nav_icon_span {
            height: 3px;
            width: 22px;
            background-color: $primary;
            transition: 0.45s ease-out;
            margin-bottom: 4px;

            &:first-of-type {
              transform-origin: right;
            }

            &:last-of-type {
              transform-origin: left;
              margin-bottom: inherit;
            }
          }
        }

        &:hover {
          .c-header_nav_icon_burger {
            .c-header_nav_icon_span {
              &:nth-of-type(2) {
                transform: scaleX(0.65);
              }
            }
          }
        }
      }
    }

    @media (max-width: 950px) {
      .c-header_links {
        display: none;
      }

      .c-header_nav {
        width: 100% !important;
        margin-top: 1rem;
        justify-content: flex-end;
      }
    }

    @media (max-width: 610px) {
      .c-header_nav {
        justify-content: flex-end;
      }

      .c-header_logo {
        width: inherit !important;
      }
    }
  }

  .c-header_nav {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .c-header_nav_don {
      @media (max-width: 1500px) {
        display: none;
      }
    }

    .c-header_nav_menu {
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  .c-header_nav_burger {
    .c-header_nav_cross {
      display: none;
    }

    .c-header_nav_icon_burger {
      display: flex;
      align-items: center;
    }

    &.is-active {
      .c-header_nav_icon_burger {
        display: none;
      }

      .c-header_nav_cross {
        display: inherit;
      }
      &:hover {
        transform: inherit;
      }
    }
    &:hover:not(.is-active) {
      .c-header_nav-icon {
        span {
          &:nth-child(1) {
            top: rem(20px);
          }
          &:nth-child(2) {
            top: rem(26px);
          }
        }
      }
    }
  }

  .c-header_flash {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
    position: relative;
    width: 100vw;
    z-index: 1;
    overflow: hidden;

    p {
      margin: inherit;

      &:last-of-type {
        width: 200%;
        white-space: nowrap;
        padding-left: rem(163px);
        animation: marqueeLike infinite linear 18s 1s;

        span {
          &:first-of-type {
            padding-right: 35vw;
          }
        }
      }
    }

    .c-header_flash_title {
      padding: 0 1.5rem;
      border-right: 1px solid rgba($color: white, $alpha: 0.5);
      margin-bottom: inherit;
      min-width: fit-content;
      height: 100%;
      background-color: $blue;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      z-index: 2;
    }

    .c-header_flash_icone {
      padding: 0 1.5rem;
      height: 100%;
      border-left: 1px solid rgba($color: white, $alpha: 0.5);
      position: absolute;
      right: 0;
      background-color: $blue;
      display: flex;
      align-items: center;
      z-index: 2;
      cursor: pointer;
    }

    &.h-closed {
      animation: close 0.3s ease-out forwards;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    justify-content: space-between;

    .-grow1 {
      .c-heading {
        display: none;
      }
    }
  }
}

@keyframes close {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
    display: none;
  }
}

@keyframes marqueeLike {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.has-navOpen {
  .c-header {
    .c-header_flash {
      animation: close 0.3s ease-out forwards;
    }
  }
}
