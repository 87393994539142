.b-equipe {
    header{
        position: relative;

        .c-slider_nav{
            span{
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                cursor: pointer;
            }

            &:last-of-type{
                right: 0;
            }
        }
    }

    .b-equipe_title{
        width: fit-content;
    }

    .b-equipe_card{
        border-bottom-left-radius: 1.5rem;
        // display: flex;
        // flex-direction: column;
        display: grid;
        grid-template-rows: 2.5fr 3fr;
    }

    .b-equipe_top {
        // flex: 2;
    }

    .b-equipe_bottom{
        // flex: 3;
        ul{
            li{
                display: flex;
                align-items: center;

                &::before{
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $blue;
                    margin-right: 0.5rem;
                }
            }
        }

        div{
            display: flex;
            align-items: center;

            span{
                min-width: 2rem;
                height: 2rem;
                background-color: $light-blue;
                border-radius: 50%;
                margin-right: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    @media (max-width: 420px){
        header{
            margin-bottom: 5.5rem !important;

            .c-slider_nav{
                left: 50%;
                right: 50%;
                bottom: -100%;

                &:first-of-type{
                    margin-right: 1rem;
                }
                
                &:last-of-type{
                    margin-left: 1rem;
                }
            }
        }
    }
}

.b-equipe2_card {
    border-bottom-left-radius: 1.5rem;
}