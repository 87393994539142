.b-media-texte {
  &.gradient {
    background: linear-gradient(to right bottom, #c956aa, 30%, $primary);
  }

  // img{
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  // }

  &.size {
    figure {
      position: absolute;
      top: 0;

      img {
        width: 47%;
      }
    }
  }
  &.size2 {
    figure {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;

      img {
        width: 47%;
      }
    }

    @media (max-width: $from-small) {
      // .b-media-texte_img{
      //     margin-bottom: 55% !important;
      // }

      figure {
        height: inherit !important;

        img {
          width: 100%;
        }
      }
    }
  }
  .b-media-texte_title {
    width: fit-content;
  }
}
