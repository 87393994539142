.b-etapes {
    .b-etapes_item_head{
        display: flex;
        align-items: flex-start;
        span{
            position: relative;
            padding: 1rem 1.5rem;
            border-bottom-left-radius: 1rem;
        }
        
        @media(max-width: 500px){
            flex-direction: column;
            align-items: center !important;

            span{
                margin-bottom: 2rem;
            }
        }
    }
}
