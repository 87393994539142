.b-chiffres {
    .b-chiffres_header{
        display: flex;
        align-items: center;
        position: relative;

        .c-slider_nav{

            &:last-of-type{
                right: 0;
            }

            .swiper-navigation_button{
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 50%;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            @media (max-width: $from-tiny){
                right: inherit;
                bottom: -4.5rem;
            }
        }
    }

    .b-chiffres_component{
        border-bottom-left-radius: 1rem;

        height: calc(100% - (#{rem($unit)} * 2));

        // div{
        //     white-space: nowrap;

        //     @media (max-width: 400px){
        //         white-space: inherit;
        //     }
        // }
    }
}
