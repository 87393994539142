.b-lien{
    background-color: $light-blue;
    .b-lien_header{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
    }

    .b-lien_component{
        display: flex;
        flex-direction: column;
        background-color: white;
        border-bottom-left-radius: 1rem;
        width: 12rem;
        padding: 0.7rem;
        // white-space: nowrap;

        .b-lien_top{
            background-color: $light-blue;
            height: rem(80px);
            width: 100%;
            border-radius: 0 0 0 rem(10px);
            overflow: hidden;
            margin-bottom: 1rem;
            figure{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img{
                width: initial;
                height: 50px;
            }
        }

        .b-lien_bottom{
            display: flex;
            align-items: center;
            padding-top: 0.5rem;
            margin-top: auto;
            border-top: 1px solid $light-blue;
            width: 100%;
            justify-content: space-between;
        }
    }
}
