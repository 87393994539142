.c-heading {
    line-height: $line-height-h;
    font-family: 'WorkSans';

    &.-title{
      font-weight: $semibold;
      width: fit-content;
    }

    &.-noMargin {
      margin-bottom: 0;
    }

    &.-h1 {
        font-size: rem($font-size-h1);
        line-height: $line-height-default;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h1)} - 30px);
        }
        @media (max-width: $to-tiny){
          font-size: 2.5rem;
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        font-weight: $semibold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h2)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 15px);
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        font-weight: $bold;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h3)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 15px);
        }
    }

    &.-small {
      font-size: rem($font-size-small);
    }

    &.-medium {
      font-size: rem($font-size-medium);

      @media (max-width: $to-small) {
        font-size: calc(#{rem($font-size-medium)} - 5px);
      }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
    }

    &.-h5 {
        font-size: rem($font-size-h5);
    }

    &.-h6 {
      font-size: rem($font-size-h6);
    }

    &.-normal {
      font-size: rem($font-size);
    }
    &.-opacity {
      opacity: 0.5;
    }

    &.-upper{
      text-transform: uppercase;
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
