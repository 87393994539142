// ==========================================================================
// Form
// ==========================================================================
.c-form {
}

.c-form_item {
  position: relative;
}

// Label
// ==========================================================================
.c-form_label {
  position: absolute;
  display: block;
  //margin-bottom: rem(10px);
  opacity: 0.6;
  top: -1rem;
  //transform: translateY(-50%);
  left: 1.5rem;
  transition: 0.3s $easing;
  pointer-events: none;
}

.c-form_item {
  &.is-active {
    label {
      transform: translateY(calc(-100% - 1.2rem));
    }
  }
}

.c-form_textarea {
  .c-form_label {
    top: 1.5rem;
  }
}

// Input
// ==========================================================================
$input-icon-color: 3C91D7; // No #

.c-form_input {
  padding: rem($unit-tiny);
  background-color: $white;
  border-radius: 0 0 0 rem(10px);
  font-weight: $semibold;

  &.-transparent {
    background-color: transparent;
    border: 1px solid rgba($white, 0.5);
    color: $white;
  }
  //border: 1px solid lightgray;

  /*&:hover {
        border-color: darkgray;
    }

    &:focus {
        border-color: dimgray;
    }*/

  /*&::placeholder {
        color: gray;
    }*/

  &::placeholder {
    color: $grey;
  }
}

// Checkbox
// ==========================================================================
$checkbox: rem(18px);
$checkbox-icon-color: $input-icon-color;

.c-form_checkboxLabel {
  @extend .c-form_label;

  position: relative;
  display: inline-block;
  margin-right: rem(10px);
  margin-bottom: 0;
  padding-left: ($checkbox + rem(10px));
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    margin-top: (-$checkbox / 2);
    padding: 0;
    width: $checkbox;
    height: $checkbox;
    content: "";
  }

  &::before {
    background-color: $white;
    border: 1px solid lightgray;
  }

  &::after {
    border-color: transparent;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
    background-position: center;
    background-size: rem(12px);
    background-repeat: no-repeat;
    opacity: 0;
  }

  &:hover {
    &::before {
      border-color: darkgray;
    }
  }

  .c-form_checkbox:focus + & {
    &::before {
      border-color: dimgray;
    }
  }

  .c-form_checkbox:checked + & {
    &::after {
      opacity: 1;
    }
  }
}

.c-form_checkbox {
  position: absolute;
  width: 0;
  opacity: 0;
}

// Radio
// ==========================================================================
$radio-icon-color: $input-icon-color;

.c-form_radioLabel {
  @extend .c-form_checkboxLabel;

  &::before,
  &::after {
    border-radius: 50%;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20enable-background%3D%22new%200%200%2013%2013%22%20xml%3Aspace%3D%22preserve%22%3E%3Ccircle%20fill%3D%22%23#{$radio-icon-color}%22%20cx%3D%226.5%22%20cy%3D%226.5%22%20r%3D%226.5%22%2F%3E%3C%2Fsvg%3E");
    background-size: rem(6px);
  }
}

.c-form_radio {
  @extend .c-form_checkbox;
}

// Select
// =============================================================================
$select-icon: rem(40px);
$select-icon-color: $input-icon-color;

.c-form_select {
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: $select-icon;
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23#{$select-icon-color}%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
    background-position: center;
    background-size: rem(8px);
    background-repeat: no-repeat;
    content: "";
    pointer-events: none;
  }

  &.-transparent {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23ffffff%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
    }
  }
}

.c-form_select_input {
  @extend .c-form_input;

  position: relative;
  z-index: 1;
  padding-right: $select-icon;
  cursor: pointer;

  &.-transparent {
    option {
      color: $primary;
    }
  }
}

// Textarea
// =============================================================================
.c-form_textarea {
  @extend .c-form_input;

  min-height: rem(200px);
}

.c-form_radio2 {
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  [type="radio"]:not(:checked) + span,
  [type="radio"]:checked + span {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    transition: 0.28s ease;
    user-select: none;
  }

  [type="radio"] + span:before,
  [type="radio"] + span:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: 0.28s ease;
  }

  [type="radio"]:not(:checked) + span:before,
  [type="radio"]:not(:checked) + span:after,
  [type="radio"]:checked + span:before,
  [type="radio"]:checked + span:after,
  [type="radio"].with-gap:checked + span:before,
  [type="radio"].with-gap:checked + span:after {
    border-radius: 50%;
  }

  [type="radio"]:checked + span:before {
    border: 2px solid transparent;
  }

  [type="radio"]:checked + span:after,
  [type="radio"].with-gap:checked + span:after {
    background-color: $primary;
  }

  [type="radio"]:checked + span:after {
    transform: scale(1.02);
  }

  [type="radio"]:not(:checked) + span:before,
  [type="radio"]:not(:checked) + span:after {
    border: 2px solid $black;
  }

  [type="radio"]:not(:checked) + span:after {
    transform: scale(0);
  }
}

.error {
  color: $red;
}
.success {
  color: $green;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  transform: translateY(-45px);
  position: relative;
  z-index: 2;
}

.custom-select {
  position: relative;
  display: flex;
  flex-direction: column;
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.8rem;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: $primary;
  background-color: white;
  cursor: pointer;

  .arrow {
    svg {
      transform: rotate(90deg);
    }
  }
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $primary;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 10;
  max-height: 300px;
  overflow: auto;
}

.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  padding: 0.8rem 1.8rem;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
}

.custom-option:hover {
  cursor: pointer;
  //color: map-get($theme-colors, 'w');
  background-color: $blue;
}

.custom-option.selected {
  cursor: pointer;
  color: white;
  background-color: $blue;
}

.level-1-menu-container {
  display: none;

  &.--active {
    display: block;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  cursor: pointer;
}

.no-js .inputfile + label {
  display: none;
}

.c-form_file {
  padding: rem($unit-small);
  border: 1px solid $primary;
  border-radius: 0 0 0 rem(10px);
  background-color: $white;
  .c-button {
    svg {
      transform: rotate(45deg);
    }
  }
}

.c-form_file_label {
  display: grid;
  grid-template-columns: rem(70px) 1fr;
  gap: rem($unit-small);
  align-items: center;
}

.c-input_checkbox {
  appearance: auto;
  width: auto;
  margin-right: 0.5rem;
  display: inline-block;
}

/* Gravityforms */
.gform_wrapper.gravity-theme input[type="color"],
.gform_wrapper.gravity-theme input[type="date"],
.gform_wrapper.gravity-theme input[type="datetime-local"],
.gform_wrapper.gravity-theme input[type="datetime"],
.gform_wrapper.gravity-theme input[type="email"],
.gform_wrapper.gravity-theme input[type="month"],
.gform_wrapper.gravity-theme input[type="number"],
.gform_wrapper.gravity-theme input[type="password"],
.gform_wrapper.gravity-theme input[type="search"],
.gform_wrapper.gravity-theme input[type="tel"],
.gform_wrapper.gravity-theme input[type="text"],
.gform_wrapper.gravity-theme input[type="time"],
.gform_wrapper.gravity-theme input[type="url"],
.gform_wrapper.gravity-theme input[type="week"],
.gform_wrapper.gravity-theme select,
.gform_wrapper.gravity-theme textarea,
.u-bg-white .c-form_input {
  border: 1px solid $primary;
  padding: 1rem;
  background-color: #e5edf9;
  border-radius: 0 0 0 0.5555555556rem;
  font-weight: 600;
}

.gform_wrapper.gravity-theme input[type="checkbox"] {
  appearance: auto;
  width: auto;
  margin-right: 0.5rem;
  display: inline-block;
}

.u-select2 {
  .select2-container {
    width: 100% !important;
    .select2-selection--single {
      background-color: $light-blue;
      border-radius: 0 0 0 0.5555555556rem;
      padding: 0.5rem;
      border: none;
      height: auto;
    }
    .select2-selection__arrow {
      background-color: transparent;
      top: 50% !important;
      transform: translateY(-50%);
    }
  }
  &.-opacity {
    .select2-container {
      .select2-selection--single {
        background-color: rgba(229, 237, 249, 0.15);
        color: $white;
      }
      .select2-selection__rendered,
      .select2-selection__placeholder {
        color: $white;
      }
    }
  }
}
