// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
  min-height: 100%; /* [2] */
  color: $primary;
  font-family: $font-family;
  font-weight: $light;
  // line-height: $line-height; /* [1] */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: $to-small) {
    font-size: $font-size - 2px;
  }

  @media (min-width: $from-small) and (max-width: $to-medium) {
    font-size: $font-size - 2px;
  }

  @media (min-width: $from-medium) and (max-width: $to-large) {
    font-size: $font-size - 1px;
  }

  @media (min-width: $from-large) and (max-width: $to-huge) {
    font-size: $font-size; /* [1] */
  }

  @media (min-width: $from-huge) and (max-width: $to-gigantic) {
    font-size: $font-size + 1px;
  }

  @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
    font-size: $font-size + 2px;
  }

  @media (min-width: $from-colossal) {
    font-size: $font-size + 4px;
  }

  &.is-loading {
    cursor: wait;
  }

  &.has-scroll-smooth {
    overflow: hidden;
  }

  &.has-scroll-dragging {
    user-select: none;
  }
}

body {
  background-color: $light-blue !important;
  transition: background-color $speed $easing;
  .has-scroll-smooth & {
    overflow: hidden;
  }
}

// main {
//   padding-top: rem($header-height);
// }

.app {
  opacity: 0;
  position: relative !important;
  transition: opacity 0.6s $easing;
}

.is-animated .app {
  opacity: 1 !important;
}

::selection {
  background-color: $selection-background-color;
  color: $selection-text-color;
  text-shadow: none;
}

a {
  color: $primary;
  transition: 0.3s $easing;

  @include u-hocus {
    color: $link-hover-color;
  }
}

.e-page_intro {
  position: relative;
  min-height: rem(445px);
  // background: linear-gradient(to right, #c956aa 0%, #c956aa 10%, #538fd1 90%, #538fd1 100%);
  background: linear-gradient(to right top, $gradient1, $gradient2, $gradient3);

  &.-bgSize {
    background-size: 67%;
    @media (max-width: $to-medium) {
      background-size: 100%;
    }
  }

  form:not(.c-form) {
    padding-top: 1rem;
    border-top: 1px solid rgba($color: white, $alpha: 0.2);
    // position: absolute;
    // bottom: -8rem;
    width: 100%;
    max-width: 70rem;

    .b-annuaire_input {
      &.-equipe {
        min-width: 300px;
      }

      &::placeholder {
        color: white;
        font-weight: $semibold;
      }
    }

    .u-bg-lightBlue {
      background-color: rgba($color: $light-blue, $alpha: 0.15);
    }
  }

  .e-page_intro_img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    @media (max-width: $to-medium) {
      position: relative;
    }
  }

  .e-page_intro_infos {
    .e-page_intro-ico {
      min-width: rem(36px);
      height: rem(36px);
      border-radius: 50%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .e-page_intro_deco {
    position: relative;
    z-index: 0;
    padding: 2rem 0.5rem 0;

    // .e-page_intro_title {
    //     position: relative;
    //     span{
    //         font-size: 200px;
    //         position: absolute;
    //         left: -7rem;
    //         bottom: 0;
    //         color: rgba($color: white, $alpha: 0.12);
    //         white-space: nowrap;
    //     }
    // }

    @media (max-width: 920px) {
      // padding: 5rem 0.5rem 0;
      width: 100% !important;
    }

    @media (max-width: $from-tiny) {
      // padding: 5rem 0.5rem;
    }
  }

  .e-page_intro_bd {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 2;
    flex-wrap: wrap;
    gap: 1rem;

    a,
    span:not(:last-of-type) {
      border-right: 1px solid white;
      padding-right: 1rem;
      color: white;
      text-decoration: underline;
    }

    span:not(:last-of-type) {
      text-decoration: unset;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }

  .e-page_intro_search {
    padding: 1rem 1.5rem;
    border: 2px solid $grey;
    border-radius: 3rem;
    max-width: 500px;
    margin: 4rem auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .e-page_intro_separator {
    width: 1.5rem;
    height: 2px !important;
    background-color: $grey;
    margin: 0 0 0 1rem;
    border-radius: inherit !important;
  }

  .e-page_intro_date {
    span {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $grey;
      margin-left: 1rem;

      svg {
        height: 1rem;
      }
    }
  }

  @media (max-width: $from-big) {
    .e-page_intro_deco {
      form {
        justify-content: flex-start !important;
      }
    }
  }

  @media (max-width: $from-midsmall) {
    .e-page_intro_deco {
      form {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  @media (max-width: $from-small) {
    .e-page_intro_deco {
      form {
        bottom: -9rem;
        align-items: flex-start !important;

        div {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }

    &.-bgSize {
      background-size: 100%;
    }
    // .e-page_intro_img{
    //     display: none;
    // }
  }

  @media (max-width: $from-tiny) {
    .e-page_intro_deco {
      p {
        bottom: 7rem;
      }

      form {
        bottom: 1rem;
      }
    }
  }
}

.e-page_intro_socials {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;

  a {
    width: rem(50px);
    height: rem(50px);
    border-radius: 50%;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    svg {
      path {
        fill: white;
      }
    }
  }
}

.e-page_intro_container {
  @media (max-width: $from-small) {
    flex-direction: column;
    padding-left: inherit !important;
    padding-right: inherit !important;

    .e-page_intro_content {
      order: 2;
      width: 100% !important;
    }

    .e-page_intro_nav {
      width: 100% !important;
    }
  }
}

::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid $grey;
  border-radius: 5px;
}

.pojo-a11y-toolbar-toggle-link {
  display: none !important;
}
#pojo-a11y-toolbar.pojo-a11y-toolbar-left.pojo-a11y-toolbar-open {
  left: auto !important;
  right: 0;
}
#pojo-a11y-toolbar.pojo-a11y-toolbar-left {
  left: auto !important;
  right: -180px;
  transition: right 750ms cubic-bezier(0.23, 1, 0.32, 1) !important;
  top: 138px !important;
}
