.c-search {
  position: relative;
  .c-search_nav_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: calc(25vw + #{rem($unit)});
      height: 100%;
    }

    @media (max-width: $from-midsmall) {
      flex-direction: column;
      align-items: center;

      .c-search_nav {
        margin-bottom: 5rem;
      }
    }
  }
  .c-search_nav {
    .c-search_nav_body {
      .c-search_nav_body_item {
        padding: 0.5rem 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey;
      }
    }
  }

  .c-search_content {
    border-bottom: 2px solid $grey;
  }

  .c-search_component {
    border-bottom-left-radius: 0.5rem;

    .c-search_top {
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $blue;
    }
  }

  p {
    margin: 0;

    &.-title {
      width: rem(220px);
    }
  }

  @media (max-width: $from-midsmall) {
    .c-search_component {
      .c-search_top {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }

  @media (max-width: $from-small) {
    .c-search_component {
      .c-search_bottom {
        & > div {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
  }

  @media (max-width: $from-tiny) {
    .c-search_component {
      .c-search_top {
        .u-c-cod {
          font-size: 1rem;
        }
      }
    }
  }
}

.c-search_modal {
  position: fixed;
  top: 140px;
  left: 0;
  right: 0;
  visibility: hidden;
  transform: translateY(calc(-10% - #{rem($header_height)}));
  opacity: 0;
  transition: 0.6s $easing;
  max-height: calc(100vh - 140px);
  display: flex;
  justify-content: flex-end;

  @media (max-width: $to-small) {
    top: 140px;
    max-height: calc(100vh - 140px);
  }

  .c-search_wrap {
    height: 100%;
    overflow: auto;
    background-color: $white;
    border-radius: 0 0 0 rem(24px);
    padding: rem($unit-small);
    width: auto;
    position: relative;
    min-width: 55vw;
  }

  .c-search_bg {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($color: $blue, $alpha: 0.7);
      z-index: -1;
    }
  }
}

.has-searchOpen {
  .c-search_modal {
    visibility: visible;
    opacity: 1;
    z-index: 9;
    transform: translateY(-0.5rem);
  }
}
