.b-onglet{
    overflow: hidden;
    .o-container{
        display: flex;
        flex-direction: column;
    }

    .b-onglet_nav{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        gap: 1rem;

        .b-onglet_nav_title{
            transform: translateY(2px);
            color: $primary;
            margin-bottom: inherit;
            cursor: pointer;

            h1{
                padding: 1rem;
                border-bottom-left-radius: 1.5rem;
                background-color: white;
                color: $primary;
                transition: 0.3s ease-out;
                width: max-content;

                &:hover{
                    background: $primary;
                    color: white;
                }
            }
        }

        // &::after{
        //     content: '';
        //     width: 100%;
        //     height: 1px;
        //     background-color: $blue;
        // }

        .clicked{
            background-color: $primary !important;
            color: white !important;
        }
    }

    .b-onglet_container{
        display: flex;
        width: max-content;
        overflow: hidden;

        .b-onglet_text{
            padding-top: 3rem;
            padding-right: 22vw;
            width: 100vw;
            transition: 0.5s ease;

            p{
                columns: 2;
                column-gap: 3rem;

                span{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: inherit;
                }
            }

            @media (max-width: 680px){
                padding-right: 5rem;

                p{
                    columns: inherit;
                }
            }

            @media (min-width: 1400px){
                padding-right: 20vw;
            }

            @media (min-width: 1800px){
                padding-right: 30vw;
            }
        }
    }
}
