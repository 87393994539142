.c-footer{
    background-color: $blue;

    p, span, a, address{
        color: $light-blue;
    }

    .c-footer_logo{
        text-transform: uppercase;
    }

    .c-footer_contact{
        .c-footer_contact_socials {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-top: 1rem;
    
            a {
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid white;
                margin-bottom: 0;
    
                svg {
                    height: 1rem;
                }
            }
        }
    }

    .c-footer_bottom_links{
        border-bottom: 2px solid $white-footer;
        opacity: 0.5;
        .c-footer_link{
            &:not(:last-of-type){
                padding-right: 4rem;
            }
            font-weight: $semibold;
            line-height: 3rem;
            color: $white-footer;
        }

        @media(max-width: 550px){
            display: flex;
            flex-direction: column;

            .c-footer_link{
                padding-right: inherit !important;
            }
        }
    }

    .c-footer_bottom{
        background-color: darken($blue, 5%);

        a, span{
            font-size: $font-size;
        }

        .o-layout_item{
            padding-right: 1rem;
        }

        @media (max-width: $from-small){
            .o-container{
                .o-layout{
                    justify-content: center;
                }
            }
        }
    }

    .c-footer_content{
        display: flex;
        align-items: flex-start;

        .c-footer_content_component{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            &:first-of-type{
                border-right: 1px solid $light-blue;
                border-left: 1px solid $light-blue;
            }

            a{
                &:not(:last-of-type){
                    margin-bottom: 1rem;
                }
            }
        }
    }

    @media (max-width: 1050px){
        .c-footer_wrap>div{
            flex-direction: column;

            .c-footer_content{
                margin: 1rem 0;
                width: 100% !important;
            }

            .c-footer_content_component{
                &:first-of-type{
                    padding-left: 0 !important;
                    border-left: inherit;
                }
            }
        }
    }

    @media (max-width: 550px){
        .c-footer_content{
            flex-direction: column;
        }

        .c-footer_content_component{
            width: 100% !important;

            &:first-of-type{
                border-right: inherit !important;
            }

            &:last-of-type{
                padding-left: 0 !important;
            }
        }
    }
}
