.b-intro {
  display: flex;
  flex-direction: column;

  &.-accueil {
    position: relative;
  }

  .b-intro_top {
    background: linear-gradient(
      to right top,
      $gradient1,
      $gradient2,
      $gradient3
    );
    display: flex;
    align-items: center;
    height: calc(100vh - 144px);

    .b-intro_img {
      height: 100%;
    }
  }

  .b-intro_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: rem($unit) 0;
    color: white;

    .b-intro_content_component {
      overflow: hidden;
      padding: 3rem;
      padding-bottom: 0;
    }
  }

  .b-intro_bottom {
    background-color: rgba($color: $blue, $alpha: 0.15);
    height: rem(200px);
    display: flex;
    align-items: center;
    justify-content: center;

    .b-intro_bottom_container {
      display: flex;
      align-items: center;
      justify-content: center;

      .b-intro_bottom_component {
        height: rem(75px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        background-color: white;
        width: rem(268px);
        border-bottom-left-radius: 1rem;

        &:not(:last-of-type) {
          margin-right: 3rem;
        }

        .b-intro_bottom_icone {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 1rem;
          border-left: 1px solid $grey-light;
        }
      }
    }

    @media (max-width: 1300px) {
      height: rem(220px);

      .b-intro_bottom_container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;

        .b-intro_bottom_component {
          margin-right: inherit !important;
        }
      }
    }

    @media (max-width: 1300px) {
      .b-intro_bottom_container {
        grid-template-columns: 1fr 1fr;
      }
    }

    @media (max-width: 510px) {
      height: rem(420px);

      .b-intro_bottom_container {
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: 1100px) {
    .b-intro_img {
      display: none;
    }

    .b-intro_container {
      margin-top: inherit;

      .b-intro_content_container {
        width: inherit;
        padding-top: 5rem;

        .b-intro_content_component {
          p {
            span {
              font-size: 100px;
            }
          }
        }
      }
    }
  }

  .b-intro_content {
    display: flex;
    flex-direction: column;
    gap: rem($unit-small);
    align-items: center;
  }
}

.c-heading_intro {
  position: relative;
  .c-heading_intro_deco {
    font-size: rem(96px);
    color: rgba($color: #fff, $alpha: 0.12);
    position: absolute;
    left: -3rem;
    top: -2.5rem;
    pointer-events: none;
    white-space: nowrap;
  }
  &.-h1 {
    .c-heading_intro_deco {
      font-size: rem(128px);
    }
  }
}

@media (max-width: 768px) {
  .c-heading_intro {
    .c-heading_intro_deco {
      font-size: rem(80px);
      left: -2.5rem;
      top: -2rem;
    }
  }
}
