.c-nav {
  position: fixed;
  top: 161px;
  left: 0;
  right: 0;
  visibility: hidden;
  transform: translateY(calc(-10% - #{rem($header_height)}));
  opacity: 0;
  transition: 0.6s $easing;
  height: calc(100vh - 140px);
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  .c-nav_bg {
    height: 30%;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($color: $blue, $alpha: 0.7);
      z-index: -1;
    }
  }
  .c-nav_wrap {
    background-color: white;
    overflow-x: auto;
    height: 100%;

    .c-nav_primary_container {
      position: relative;

      .c-nav_bandeau {
        height: rem(70px);
        background-color: rgba($color: $blue, $alpha: 0.15);
        width: 100%;
      }

      .c-nav_primary_right {
        position: absolute;
        top: 0;
        right: 0;
        bottom: rem(70px);

        figure {
          overflow: hidden;

          img {
            transform: scale(1.2);
            transition: 0.6s ease-out;
          }
        }
      }

      // @media(max-width: 1600px){
      //   .o-layout{
      //     display: none;
      //   }
      // }

      // @media(max-width: 350px){
      //   padding-left: 5.5rem !important;
      // }

      .c-nav_primary {
        &::before {
          display: none;
        }
      }
    }
  }
  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-nav_primary {
    display: flex;
    flex-direction: column;
    transition: 0.3s $easing;
    transition-delay: 0.3s;
    color: $primary;
    position: relative;
    height: calc(100% - 8rem);

    .is-active {
      opacity: 0.5;
    }

    .c-nav_primary_component {
      overflow: hidden;
    }

    .c-nav_primary_sublink {
      transform: translateY(10rem);
      transform-origin: top;
      transition: 0.3s ease-out, opacity 0s;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 0;
      opacity: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      width: calc(100% - 3rem);

      &::-webkit-scrollbar {
        width: 0.4em;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color: $primary, $alpha: 0.4);
        outline: 1px solid rgba($color: $primary, $alpha: 0.4);
        border-radius: 5px;
      }

      .c-nav_sublink {
        // cursor: pointer;
        transition: 0.3s ease-out;
        margin-top: 1.5rem;

        &:hover {
          transform: translateX(1rem);
        }
      }
      .-under-under_link {
        margin-top: 0.75rem !important;
        font-size: 0.85rem;
      }
    }

    .c-nav_primary_link {
      cursor: pointer;
      display: grid;
      grid-template-columns: 18px 1fr;
      grid-gap: 1rem;
      align-items: center;
      position: relative;
      transition: 0.3s ease-out;

      .c-nav_primary_plus {
        width: 1.2rem;
        height: 4px;
        background: $blue;
        position: relative;
        transition: 0.3s;

        &::after {
          content: "";
          height: 1.2rem;
          width: 4px;
          background-color: $blue;
          position: absolute;
          left: calc(50% - 2px);
          top: calc(-0.6rem + 2px);
          transition: 0.3s ease-out;
          transform-origin: center;
        }
      }

      &.-underlink {
        transform: translateX(calc(-18px - 1rem));
      }

      &:hover {
        &.-underlink {
          transform: translateX(0);
        }

        &.-link {
          transform: translateX(1.8rem);
        }
      }
    }

    .h-clicked {
      overflow: visible;
      .c-nav_primary_link {
        color: $blue;

        span {
          background-color: $blue;
        }

        &.-underlink {
          transform: translateX(0);
        }
      }
      .c-nav_primary_sublink {
        transform: translateY(0rem);
        height: 100%;
        margin-top: 2rem;
        opacity: 1;
      }

      .c-nav_primary_link {
        .c-nav_primary_plus {
          transform: rotate(45deg);
        }
      }

      @media (min-width: $from-large) {
        .c-nav_primary_sublink {
          animation: sublink 0.3s ease-out forwards;
        }
      }
    }

    // &::after{
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   background-color: white;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
  }

  @media (max-width: $from-big) {
    .c-nav_primary_right {
      a {
        &:last-of-type {
          display: none;
        }
      }
    }
  }

  @media (min-width: $from-large) {
    .c-nav_primary {
      width: fit-content;
    }

    .c-nav_primary_sublink {
      position: absolute;
      top: 0;
      margin-top: 2rem;
      border-left: 1px solid $primary;
      padding-left: 2rem;
      left: 0;
      visibility: hidden;
      transform: translateY(0) !important;
      width: 100%;
    }
  }

  @media (max-width: $from-large) {
    top: 140px;

    .c-nav_primary_sublink {
      width: 100% !important;
    }
  }

  @media (max-width: $from-medium) {
    .c-nav_bg {
      display: none;
    }

    .c-nav_wrap {
      width: 100% !important;
    }
  }

  @media (max-width: $to-small) {
    top: 166px;
    height: calc(100vh - 166px);
  }

  @media (max-width: $from-midsmall) {
    .c-nav_primary_right {
      display: none;
    }

    .c-nav_primary {
      width: 100% !important;
    }
  }

  @media (max-width: $from-tiny) {
    .c-nav_primary {
      // padding-right: 5rem;
      .c-nav_primary_link {
        &.-underlink {
          transform: translateX(0) !important;
        }
      }
    }
  }

  @media (max-width: $to-medium) {
    .custom-select-wrapper {
      width: 90%;
    }
  }
}

.has-navOpen {
  .c-header {
    .c-header_nav_icon_span {
      &:first-of-type {
        transform: translateX(-22px) rotate(-140deg);
      }

      &:nth-of-type(2) {
        transform: rotate(-90deg);
        opacity: 0;
      }

      &:last-of-type {
        transform: rotate(-38deg);
      }
    }
  }
  .c-nav {
    visibility: visible;
    opacity: 1;
    z-index: 9;
    transform: translateY(-0.5rem);
    .c-nav_shop {
      opacity: 1;
      transform: translateY(0);
    }
    .c-nav_image-bg {
      transform: translateY(0);
    }

    .c-nav_primary_container {
      .c-nav_primary_right {
        figure {
          img {
            transform: scale(1);
          }
        }
      }
    }
  }
}

@keyframes sublink {
  0% {
    left: 0;
    visibility: hidden;
  }

  50% {
    visibility: hidden;
    left: 90%;
  }

  90% {
    visibility: visible;
  }

  100% {
    visibility: visible;
    left: 100%;
  }
}
