#formPaiement {
    .info {
        [data-tooltip] {
            opacity: 0;
            display: none;
            position: absolute;
            border-bottom-left-radius: 1rem;
            right: 0;
            border: 3px solid #3c91d7;
            z-index: 10;
        }
    
        &:hover {
            [data-tooltip] {
                opacity: 1;
                display: block;
            }
        }
    }
    
    .references {
        align-items: center;
    
        #reference3 {
            width: 50px;
        }
    }
}