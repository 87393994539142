// ==========================================================================
// Base / Fonts
// ==========================================================================

// Regular

@include font-face(
    $font-WorkSans,
    "fonts/WorkSans-Regular",
    ("eot", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 400;
}

//Bold

@include font-face(
    $font-WorkSans,
    "fonts/WorkSans-Bold",
    ("eot", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 700;
}

// SemiBold

@include font-face(
    $font-WorkSans,
    "fonts/WorkSans-SemiBold",
    ("eot", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 600;
}

// Medium

@include font-face(
    $font-WorkSans,
    "fonts/WorkSans-Medium",
    ("eot", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 500;
}
