.c-button {
  padding: rem(12px) rem(24px);
  border-bottom-left-radius: rem(20px);
  border: 3px solid rgba($color: $blue, $alpha: 0.15);
  position: relative;
  width: fit-content;
  background-color: white;
  color: $primary;
  display: inline-flex;
  align-items: center;

  &::before{
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    left: -3px;
    top: -3px;
    border-top: 3px solid $primary;
    border-right: 3px solid $primary;
    visibility: hidden;
  }

  &::after{
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    right: 0px;
    bottom: -3px;
    border-left: 3px solid $primary;
    border-bottom: 3px solid $primary;
    border-bottom-left-radius: rem(20px);
    visibility: hidden;
  }

  &:hover{
    &::before{
        visibility: visible;
        height: calc(100% + 6px);
        width: calc(100% + 6px);
        transition: width 0.15s linear,
                    height 0.15s linear 0.15s;
    }

    &::after{
        visibility: visible;
        height: calc(100% + 6px);
        width: calc(100% + 3px);
        transition: width 0.15s linear 0.3s,
                    visibility 0s linear 0.3s,
                    height 0.15s linear 0.45s;
    }
  }

  &.-round{
    
  }

  &.-blue{
    border: inherit;
    background-color: $blue;
    color: white;

    &::before, &::after{
        display: none;
    }
  }

  &.-w-large{
    width: inherit;
  }

  &.-bg-colored{
      background-color: inherit;

      &::after{
        border-left: 3px solid white;
        border-bottom: 3px solid white;
      }

      &::before{
        border-top: 3px solid white;
        border-right: 3px solid white;
      }
  }

  svg {
    width: 1rem;
    max-height: 1rem;
  }
}

$width  : fit-content;
$height : fit-content;
$primary_color : #3084AC;
$border_color--hover : lighten($primary_color, 80);
$stroke : 2px;
$border: $stroke solid white;
$transition_duration:150ms;
$pipeline_width: 100%;
$pipeline_height: 100%;
$pipes : 4;

@function border-pipeline ($transition_duration, $pipe) {
    @return $transition_duration*$pipe;
}

@mixin border-animation($dimension, $easing ,$pipe ) 
{
    transition: $transition_duration  $dimension $easing border-pipeline($transition_duration,$pipe);
    -web-kit-transition: $transition_duration  $dimension $easing border-pipeline($transition_duration,$pipe);
}

.box {
    width: $width ;
    height: $height ;
    border:$border;
    text-align: center;
    position:relative;
    cursor: pointer;
    transition: ($transition_duration*$pipes)+$transition_duration ease;
    border-bottom-left-radius: 1rem;
    &:before {
        position: absolute; 
        width: 0; 
        height:$stroke; 
        content: ''; 
        background: $primary;
        top:-$stroke; 
        left:0; 
        @include border-animation( width, ease, 3);
    }
    &:after {
        position: absolute; 
        width:$stroke; 
        height:0;
        content: ''; 
        background: $primary; 
        top:-$stroke; 
        right: -$stroke; 
        @include border-animation( height, ease, 2);
    }
    &-inner{
      position: relative;
    }
    &-inner:after{
        position: absolute; 
        width: 0; 
        height:$stroke; 
        content: ''; 
        background: $primary; 
        bottom:0; 
        right: 0;
        @include border-animation( width, ease, 1);
    }
    &-inner:before {
        position: absolute; 
        width: $stroke; 
        height:0;content: ''; 
        background: $primary; 
        bottom:0; 
        left: 0; 
        @include border-animation( height, ease, 0); 
    }
    &:hover{
        border-color:transparent;
        &:before{
            width: $pipeline_width; 
            @include border-animation( width, ease, 0); 
        }
        &:after {
            height:$pipeline_height; 
            @include border-animation( height, ease, 1); 
        }
        .box-inner:after {
            width: $pipeline_width; 
            @include border-animation( width, ease, 2);
        }
        .box-inner:before {
            height:$pipeline_height; 
            @include border-animation( height, ease, 3);
        }
    }
}

.c-button_round {
  width: rem(48px);
  height: rem(48px);
  background-color: $white;
  color: $primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .c-button_round_icon {
    line-height: 0;
  }

  &.-disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.-small {
    width: rem(32px);
    height: rem(32px);
    svg {
      max-height: rem(15px);
      max-width: rem(15px);
      width: 1rem;
    }
  }

  &.-outline {
    border: 1px solid $primary;
  }

  &:hover {
    &.-outline {
      background-color: $primary;
      color: $white;
    }
  }
}