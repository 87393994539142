.b-annuaire {
  .b-annuaire_deco {
    display: flex;
    align-items: center;
    width: 100%;

    &::before {
      content: "";
      width: 100%;
      height: 2px;
      margin-right: 2rem;
      background-color: $hr;
    }

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      margin-left: 2rem;
      background-color: $hr;
    }
  }
  .b-annuaire_container {
    border-bottom-left-radius: 1.5rem;
    min-width: fit-content;

    .b-annuaire_input {
      border-bottom-left-radius: 0.5rem;

      &.-equipe {
        min-width: rem(300px);
      }
    }

    .c-button {
      padding: calc(0.5rem - 3px) 1rem;
    }
  }

  @media (max-width: $from-medium) {
    .b-annuaire_deco {
      width: auto;
      &::after,
      &::before {
        display: none;
      }
    }
  }

  @media (max-width: $from-midsmall) {
    form {
      flex-direction: column;
      gap: 1rem;
    }
  }

  @media (max-width: $from-small) {
    .b-annuaire_container {
      margin: 0 auto;
    }

    form {
      div {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}

.u-annuaire_form {
  width: $from-small;
  max-width: 100%;
  margin: 0 auto;
}
